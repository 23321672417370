import React, { useEffect } from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

// Utils
import { ImageOrientation } from "../utils/image-orientation";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding: 190px 100px 0 100px;

  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 190px 50px 0 50px;
  }

  @media (max-width: 900px) {
    padding: 110px 0 0 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 50px;

  grid-auto-flow: column;

  width: 100%;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    grid-gap: 0;
    grid-template-columns: unset;
    grid-auto-flow: unset;
  }
`;

const Intro = styled.div`
  width: 100%;

  padding: 0 0 100px 0;
  margin: 0 auto;

  & .text {
    width: 100%;
    max-width: 850px;
    text-align: center;

    padding: 50px 0;
    margin: 0 auto;

    font-size: 36px;
    line-height: 36px;

    letter-spacing: 0.01em;

    & p:first-of-type {
      margin-top: 0;
    }

    & p:last-of-type {
      margin-bottom: 0;
    }

    @media (max-width: 1300px) {
      font-size: 28px;
      line-height: 36px;
    }

    @media (max-width: 900px) {
      padding: 40px 20px 0 20px;

      font-size: 24px;
      line-height: 27px;
    }
  }

  @media (max-width: 900px) {
    padding: 0 0 45px 0;
  }
`;

const PageLink = styled.div`
  margin: 0 0 125px 0;

  &:nth-of-type(even) {
    & .gallery {
      grid-column: 1 / 6;
    }

    & .text-container {
      grid-column: 6 / 9;
    }
  }

  &:nth-of-type(odd) {
    & .gallery {
      grid-column: 4 / 9;
    }

    & .text-container {
      grid-column: 1 / 4;
    }
  }

  & .title h2 {
    font-size: 48px;
    line-height: 55px;
    letter-spacing: 0.01em;
    text-align: center;

    @media (max-width: 1300px) {
      font-size: 36px;
      line-height: 48px;
    }

    @media (max-width: 900px) {
      font-size: 26px;
      line-height: 30px;
    }
  }

  & .text p {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.04em;
    text-align: center;

    @media (max-width: 1300px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  & img.portrait {
    max-height: 600px;
    object-fit: contain;
  }

  @media (max-width: 900px) {
    &:nth-of-type(odd),
    &:nth-of-type(even) {
      & .gallery,
      & .text-container {
        width: 100%;
      }
    }
  }

  @media (max-width: 900px) {
    margin: 0 0 60px 0;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: center;

  font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;

  & strong {
    font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;
    font-size: 12px;
    line-height: 126.5%;
    text-transform: uppercase;
  }

    }

  @media (max-width: 1080px) {
    margin: 20px 0 0 0;
  }

  @media (max-width: 900px) {
    padding: 0 20px;

    & h2 {
      font-size: 26px;
      line-height: 30px;
    }
  }
`;

const WhatWeDoContent = ({ data, setPageType }) => {
  useEffect(() => {
    setPageType("what-we-do");
  }, []);

  const content = data.prismicWhatWeDo.data.body.map((content, index) => {
    if (content.slice_type === "section") {
      return (
        <PageLink key={content.id}>
          <Grid>
            <div className="gallery">
              <Link to={content.primary.page_link.url}>
                {content.primary.image.fluid !== null && (
                  <img
                    className={ImageOrientation(content.primary.image)}
                    srcSet={content.primary.image.fluid.srcSetWebp}
                    src={content.primary.image.fluid.srcWebp}
                    alt={content.primary.image.alt}
                    loading="lazy"
                  />
                )}
              </Link>
            </div>
            <TextContainer className="text-container">
              <Link to={content.primary.page_link.url}>
                <div
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: content.primary.linked_page_title.html,
                  }}
                />

                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: content.primary.linked_page_text.html,
                  }}
                />
              </Link>
            </TextContainer>
          </Grid>
        </PageLink>
      );
    }

    if (content.slice_type === "video_section") {
      return (
        <PageLink key={content.id}>
          <Grid>
            <div className="gallery">
              <Link to={content.primary.page_link.url}>
                {content.primary.vimeo_mp4_file.url !== "" && (
                  <video
                    src={content.primary.vimeo_mp4_file.url}
                    muted
                    loop
                    autoPlay
                    playsInline
                    preload="metadata"
                  />
                )}
              </Link>
            </div>
            <TextContainer className="text-container">
              <Link to={content.primary.page_link.url}>
                <div
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: content.primary.linked_page_title.html,
                  }}
                />

                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: content.primary.linked_page_text.html,
                  }}
                />
              </Link>
            </TextContainer>
          </Grid>
        </PageLink>
      );
    }
  });

  return (
    <>
      <Helmet
        title={`What We Do – Park Village`}
        meta={[
          {
            name: "og:title",
            content: `What We Do – Park Village`,
          },
          {
            name: "twitter:title",
            content: `What We Do – Park Village`,
          },
        ]}
      />
      <Container>
        <Intro>
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: data.prismicWhatWeDo.data.text.html,
            }}
          />
        </Intro>
        {content}
      </Container>
    </>
  );
};

const WhatWeDo = ({ data }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <WhatWeDoContent setPageType={setPageType} data={data} />
    )}
  </PageTypeConsumer>
);

export default withPreview(WhatWeDo);

export const query = graphql`
  {
    prismicWhatWeDo {
      data {
        text {
          html
        }
        body {
          ... on PrismicWhatWeDoBodySection {
            id
            slice_type
            primary {
              linked_page_title {
                html
              }
              linked_page_text {
                html
              }
              image {
                alt
                fluid {
                  srcWebp
                  srcSetWebp
                }
                dimensions {
                  height
                  width
                }
              }
              page_link {
                url
              }
            }
          }
          ... on PrismicWhatWeDoBodyVideoSection {
            id
            slice_type
            primary {
              vimeo_mp4_file {
                url
                id
              }
              page_link {
                id
                url
              }
              linked_page_title {
                html
              }
              linked_page_text {
                html
              }
            }
          }
        }
      }
    }
  }
`;
